import { API_APP_URL } from "../env/consts";
import { getSessionId, getGtagSessionId } from "./session";

declare global {
  interface Navigator {
    connection: any;
    mozConnection: any;
    webkitConnection: any;
  }
}

// connection type
let connectionType = '';
try {
  const connection = navigator.connection || navigator.mozConnection || navigator.webkitConnection;
  connectionType = (connection && connection.effectiveType) || '';
  connection.addEventListener('change', () => {
    connectionType = connection.effectiveType;
  });
} catch (err) {}

export default function logCustomEvent (eventName: string, data?: any) {
  
  // current page and winddow.navigator
  let page = '';
  let webInfo = {};
  if (typeof window !== "undefined") {
    page = window.location.pathname;
    webInfo = {
      vendor: window?.navigator?.vendor,
      appVersion: window?.navigator?.appVersion,
      userAgent: window?.navigator?.userAgent,
      platform: window?.navigator?.platform,
      productSub: window?.navigator?.productSub
    }
  }

  // payload
  const payload = {
    eventName,
    eventTimestamp: Date.now(),
    sessionId: getSessionId(),
    gtag: getGtagSessionId(),
    page,
    connectionType,
    screenW: window?.screen?.width,
    screenH: window?.screen?.height,
    webInfo,
    ...(data || {})
  }

  fetch(`${API_APP_URL}analytics?eventName=${eventName}`, {
    method: 'POST',
    mode: 'cors',
    cache: 'no-cache',
    headers: {
      "Content-Type": "application/json"
    },
    body: JSON.stringify(payload)
  });
}