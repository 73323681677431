import 'tailwindcss/tailwind.css';
import { AppProps } from 'next/app';
import '../styles/globals.css';
import 'keen-slider/keen-slider.min.css';
import '../components/basic/3d-carousel.vendor.scss';
import Head from 'next/head';
import { useRouter } from 'next/router';
import { useEffect } from 'react';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import '../styles/react-toastify-customizations.scss';
// import { useSafeRedirect } from '../lib/useSafeRedirect';
import { analytics, logEvent } from '../lib/firebase';
import { useAuthInfo } from '../lib/jwt';
import { Chain } from '../lib/getGraphqlClient';
import SessionResolver from '../components/SessionResolver';
import logCustomEvent from '../helpers/analytics';

function MyApp({ Component, pageProps }: AppProps) {
  const router = useRouter();
  const [authInfo] = useAuthInfo(false);

  // useSafeRedirect('beta.voyce.me', 'voyce.me', 3);

  const initFBPixel = async () => {
    import('react-facebook-pixel')
      .then((x) => x.default)
      .then((ReactPixel) => {
        ReactPixel.init('582105357115193')
        ReactPixel.pageView()

        router.events.on('routeChangeComplete', () => {
          ReactPixel.pageView()
        })
      })
  }

  const onRightClick = (e: any) => {
    // e.preventDefault();
  }

  useEffect(() => {
    // fb pixel
    // initFBPixel();
  }, [router.events]);

  useEffect(() => {
    if (authInfo && authInfo.id) { 
      Chain()
      .query({
        voyce_users: [
          {
            where: {
              id: { _eq: authInfo.id }
            },
            limit: 1
          },
          {
            id: true,
            private_fields: {
              email: true,
              username: true,
              nickname: true,
            }
          }
        ]
      })
      .then(d => {
        const users = d?.voyce_users;
        const user = users.length ? users[0] : {} as any;
        const email = user?.private_fields?.email;
        const externalId = user?.private_fields?.username;

        import('react-reddit-pixel')
          .then((x) => x.default)
          .then(RedditPixel => {
            const options = {
              debug: true, // set true to enable logs
              optOut: false,
              useDecimalCurrencyValues: true,
              email,
              externalId
            };
            RedditPixel.init('t2_w1blw5wm', options);
            RedditPixel.pageVisit();
            RedditPixel.track('ViewContent');
            RedditPixel.track('Search');
          });
      });
    } else {
      import('react-reddit-pixel')
        .then((x) => x.default)
        .then(RedditPixel => {
          const options = {
            debug: true, // set true to enable logs
            optOut: false,
            useDecimalCurrencyValues: true,
          };
          RedditPixel.init('t2_w1blw5wm', options);
          RedditPixel.pageVisit();
          RedditPixel.track('ViewContent');
          RedditPixel.track('Search');
        });
    }

  }, [authInfo])

  useEffect(() => {
    // analytics
    logCustomEvent('PageLoad');
    window.addEventListener("contextmenu", (e) => onRightClick(e));
    return () => {
      // return a cleanup function to unregister our function since it's going to run multiple times
      window.removeEventListener("contextmenu", (e) => onRightClick(e));
    };
  }, []);

  // useEffect(() => {
  //   const handleRouteChange = url => {
  //     logEvent(analytics, 'screen_view', {
  //       firebase_screen: url, 
  //       firebase_screen_class: "web_screen"
  //     });
  //   };

    
  //   //When the component is mounted, subscribe to router changes
  //   //and log those page views
  //   router.events.on('routeChangeComplete', handleRouteChange);

  //   // If the component is unmounted, unsubscribe
  //   // from the event with the `off` method
  //   return () => {
  //     router.events.off('routeChangeComplete', handleRouteChange);
  //   };
  // }, [router.events]);

  return (
    <>
      <Head>
        <meta name="viewport" content="width=device-width, initial-scale=1" />
      </Head>
      <SessionResolver />
      <Component {...pageProps} />
      <ToastContainer
        position="bottom-center"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
      />
    </>
  );
}

export default MyApp;
