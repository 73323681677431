
import { generateRandomString } from "./randomize"
const sessionKey = 'session_id';

export function getSessionId() {
  try {
    const value = window.sessionStorage.getItem(sessionKey);
    return value || null;
  } catch (error) {
    console.error('Error getting session id:', error);
    return null;
  }
}

export function setSessionId() {
  try {
    const value = generateRandomString(16);
    window.sessionStorage.setItem(sessionKey, value);
    return value;
  } catch (error) {
    console.error('Error setting session id:', error);
  }
}

export function removeSessionId() {
  try {
    window.sessionStorage.removeItem(sessionKey);
  } catch (error) {
    console.error('Error removing session id:', error);
  }
}

export function getGtagSessionId() {
  try {
    const value = window.sessionStorage.getItem('gtag_session_id');
    return value ? JSON.parse(value) : "NA";
  } catch (error) {
    console.error('Error getting gtag session id:', error);
    return "NA";
  }
}