import React, { useEffect } from 'react'
import { getSessionId, removeSessionId, setSessionId } from '../helpers/session';
import { useIdleTimer } from "react-idle-timer";

export default function SessionResolver() {

  useEffect(() => {
    if (!getSessionId()) {
      setSessionId();
    }
  }, []);

  const onActive = () => {
    if (!getSessionId()) {
      setSessionId();
    }
  }
  const onIdle = () => {
    removeSessionId();
  }

  const idleTimer = useIdleTimer({
    onActive: onActive,
    onIdle: onIdle,
    debounce: 250,
    timeout: (60 * 1000) * 15, // 15 minutes
  });

  return null
}